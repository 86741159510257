function animateAnchor(target, offset, speed) {
	if (target.length) {
		$('html,body').stop().animate({
			scrollTop: target.offset().top - offset
		}, speed);

		return false;
	}

	return true;
}

export function anchorOnClick(offset, speed) {
	$('a[href*=\\#]:not([href=\\#])').click(function anchorClick() {
		if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
		|| window.location.hostname === this.hostname) {
			let target = $(this.hash);

			target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);

			animateAnchor(target, offset, speed);
		}
	});
}

export function anchorOnLoad(offset, speed) {
	let target = window.location.hash;

	if (target) {
		target = $(target);

		animateAnchor(target, offset, speed);
	}
}
