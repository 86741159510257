/**
* Setup webpack public path
* to enable lazy-including of
* js chunks
*
*/
/* ----global urls:true---- */
import 'bootstrap';
import 'slick-carousel';
import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/inview';
import 'waypoints/lib/shortcuts/infinite';


import { anchorOnClick } from "./utils/anchor-scroll";
import initGoogleMap from './scripts/google-maps';

const $ = window.jQuery;


$(() => {
	// AOS.init();
	anchorOnClick(200, 300);

	$('.feature-block .gallery, .room .gallery').slick({
		// autoplay: true,
		arrows: false,
		fade: true,
		autoplaySpeed: 2000,
		speed: 1000,
		pauseOnFocus: false
	});

	$('#gallery').slick({
		autoplay: true,
		fade: true,
		autoplaySpeed: 4000,
		speed: 1200,
		pauseOnFocus: false,
		appendArrows: '#galleryNav',
		prevArrow: '<button type="button" class="gallery__prev"><i class="far fa-arrow-left"></i></button>',
		nextArrow: '<button type="button" class="gallery__next"><i class="far fa-arrow-right"></i></button>'
	});

	$('.feature-block').each((index, item) => {
		new window.Waypoint.Inview({
			element: $(item),
			enter: () => {
			},
			entered: () => {
				$(item).find('.gallery').slick('slickPlay');
			},
			exit: () => {
			},
			exited: () => {
				$(item).find('.gallery').slick('slickPause');
			}
		});
	});

	$('.animate').each((index, item) => {
		$(item).addClass('ready');

		new window.Waypoint.Inview({
			element: $(item),
			enter: (direction) => {

				if (direction === 'down') {

					if ($(item).hasClass('animate--delay')) {
						setTimeout(() => {
							$(item).addClass('inview');
						}, 1500);
					} else {
						$(item).addClass('inview');
					}
				}
			},
			entered: () => {
			},
			exit: () => {
				// $(item).removeClass('inview');
			},
			exited: () => {

			}
		});
	});

	$('#homeMain').waypoint({
		handler: direction => {
			if (direction === 'down') {
				$('#homeMain').addClass('inview');
				// console.log('inview');
			}
		},
		offset: '75%'
	});

	$('#hero').waypoint({
		handler: direction => {
			if (direction === 'down') {
				$('#book').addClass('small');
			}

			if (direction === 'up') {
				$('#book').removeClass('small');
			}
		},
		offset: '-20%'
	});

	// Add class to body when mobile nav open
	const mainNav = document.getElementById('mainNav');

	mainNav.addEventListener('show.bs.collapse', () => {
		$('body').addClass('nav-open');
	});

	mainNav.addEventListener('hide.bs.collapse', () => {
		$('body').removeClass('nav-open');
	});

	$('#mainNav').on('click', '.navbar__item--has-children:not(.show-submenu)', (e) => {
		const {currentTarget} = e;

		$('#mainNav').addClass('fade-out-in');
		$('#mainNav').addClass('show-submenu');

		setTimeout(() => {
			$('#mainNav  > .navbar__list > .navbar__item').addClass('hidden');
		}, 800);

		setTimeout(() => {
			$(currentTarget).removeClass('hidden');
			$(currentTarget).addClass('show-submenu');
			$('#mainNav').removeClass('fade-out-in');
		}, 1000);
	});

	$('#mainNav').on('click', '.navbar__item--has-children.show-submenu', (e) => {
		const {currentTarget} = e;

		$('#mainNav').addClass('fade-out-in');

		setTimeout(() => {
			$(currentTarget).addClass('hidden');
			$(currentTarget).removeClass('show-submenu');
		}, 800);

		setTimeout(() => {
			$('#mainNav  > .navbar__list > .navbar__item').removeClass('hidden');
			$('#mainNav').removeClass('show-submenu');
			$('#mainNav').removeClass('fade-out-in');
		}, 1000);

	});

	$('#bookButton').on('click', (e) => {
		const {currentTarget} = e;

		$(currentTarget).parents('.book').toggleClass('show');
	});


	// Keep track of main elements that are inview
	const mainElements = ['.hero', '#main > .section__section', '.footer'];

	mainElements.forEach((value) => {
		const element = $(value);

		element.each((index, el) => {
			const elementBg = $(el).data('bg');

			new window.Waypoint.Inview({
				element: $(el),
				entered: (direction) => {
					$('body').attr('data-bg-inview', elementBg);
				},
			});
		});
	});

	// eslint-disable-next-line no-unused-vars
	const infinite = new window.Waypoint.Infinite({
		element: $('#gigs')[0],
		more: '.more-posts',
		items: '.events__event',

	});

});

$(window).resize(() => {

});

// Make Google maps globally available
if (!('initMap' in window)) {
	window.initMap = initGoogleMap;
}

