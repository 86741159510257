import {isMobile} from '../utils/detect-browser';

export default function () {
	const {google} = window;
	const latPt = -38.337360;
	const longPt = 144.319610;
	const mapContainer = document.getElementById('map');

	if (!mapContainer) {
		return;
	}

	const markerPath = `${$('#map').data('path')}/assets/svg/tqh-map-marker.svg`;

	const mapStyle = [
		{
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				}
			]
		},
		{
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#616161"
				}
			]
		},
		{
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"color": "#f5f5f5"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#5e73a2"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#002c6e"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "administrative.neighborhood",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#5e73a2"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#5e73a2"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.business",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "poi.school",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "poi.sports_complex",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#01285f"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#264373"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#5e73a2"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dadada"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#2c426f"
				}
			]
		},
		{
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e5e5e5"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit.station",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#eeeeee"
				}
			]
		},
		{
			"featureType": "transit.station.airport",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#002c6e"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e7ebf2"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#001b50"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#5e73a2"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		}
	];

	/* eslint-disable no-unused-vars */
	const map = new google.maps.Map(mapContainer, {
		center: {lat: latPt, lng: longPt},
		zoom: 17,
		styles: mapStyle,
		disableDefaultUI: true
	});

	const markerImage = {
		url: markerPath,
		size: new google.maps.Size(300, 300),
		scaledSize: new google.maps.Size(100, 100),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(30, 70),
		optimzed: false
	};

	const mapMarker = new google.maps.Marker({
		position: {lat: latPt, lng: longPt},
		map,
		icon: markerImage
	});


	if (isMobile()) {
		map.setOptions({draggable: false});
	}
}
